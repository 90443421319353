var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "stage-completed-slide" },
    [
      _c("h3", { staticClass: "text-center mb-4" }, [
        _vm._v("\n    " + _vm._s(_vm.heading) + "\n  "),
      ]),
      _c("div", { staticClass: "image-container mt-5 mb-5" }, [
        _c("picture", [
          _c("source", {
            attrs: {
              srcset: require("@images/illustrations/stageline/stageline-continue-sign.webp"),
              type: "image/webp",
            },
          }),
          _c("img", {
            staticClass: "image",
            attrs: {
              src: require("@images/illustrations/stageline/stageline-continue-sign.png"),
              alt: "Continue",
            },
          }),
        ]),
      ]),
      _c(
        "b-button",
        {
          staticStyle: { float: "right" },
          attrs: { variant: "primary", "aria-label": "continue button" },
          on: {
            click: function ($event) {
              return _vm.$emit("continue")
            },
          },
        },
        [
          _c("span", { staticStyle: { padding: "0 .5rem" } }, [
            _vm._v("\n      Continue\n    "),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }