<template>
  <div class="stage-completed-slide">
    <h3 class="text-center mb-4">
      {{ heading }}
    </h3>
    <div class="image-container mt-5 mb-5">
      <picture>
        <source
          :srcset="require('@images/illustrations/stageline/stageline-continue-sign.webp')"
          type="image/webp"
        >
        <img
          :src="require('@images/illustrations/stageline/stageline-continue-sign.png')"
          alt="Continue"
          class="image"
        >
      </picture>
    </div>
    <b-button
      style="float: right;"
      variant="primary"
      aria-label="continue button"
      @click="$emit('continue')"
    >
      <span style="padding: 0 .5rem">
        Continue
      </span>
    </b-button>
  </div>
</template>

<script>
export default {
  name: 'StageCompletedSlide',
  data() {
    return {
      heading: "You’ve finished all your tasks here!",
    }
  },
}
</script>

<style lang="scss" scoped>
.image-container {
  display: flex;
  justify-content: center;
  flex: 0 1 50%;
  picture {
    .image {
      height: 260px;
    }
  }
}
</style>
